/**
 * src/App.jsx
 *
 * created by Lynchee on 7/14/23
 */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { getHostName } from './utils/urlUtils';

// Components
import Header from './components/Header';
import { Alert, Snackbar } from '@mui/material';

// Pages
import Settings from './pages/Settings';
import Conversation from './pages/Conversation';
import SharedConversation from './pages/SharedConversation';
import Home from './pages/Home';
import CharCreate from './pages/CharCreate';
import CharDelete from './pages/CharDelete';
import Privacy from './pages/Privacy';
import Support from './pages/Support';
import Login from './pages/Login';
import Auth from './pages/Auth';
import User from './pages/User';
import BindWx from './pages/BindWx';
import Libai from './libai/Libai.jsx';
import Mobile from './mobile/Mobile.jsx';
import Jintaiyang from './jintaiyang/Mobile.jsx';
import Survey from './survey/index.jsx';

// Custom hooks
import useWebsocket from './hooks/useWebsocket';
import useRecord from './hooks/useRecord';
import useXunFeiRecognition from './hooks/useXunFeiRecognition';
import useWebRTC from './hooks/useWebRTC';
import useHark from './hooks/useVAD';
import { getSessionIdTemp } from './hooks/sessionManage';
import useConversation from './hooks/useConversation.js';

const App = () => {
  const [sessionId, setSessionId] = useState('');
  const [commMethod, setCommMethod] = useState('Text');
  const [preferredLanguage, setPreferredLanguage] = useState('English');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo-16k');
  const [useSearch, setUseSearch] = useState(false);
  const [useQuivr, setUseQuivr] = useState(false);
  const [quivrApiKey, setQuivrApiKey] = useState('');
  const [quivrBrainId, setQuivrBrainId] = useState('');
  const [useMultiOn, setUseMultiOn] = useState(false);
  const [useEchoCancellation, setUseEchoCancellation] = useState(false);
  const [user, setUser] = useState(null);
  const isLoggedIn = useRef(false);
  const [token, setToken] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [messageInput, setMessageInput] = useState('');
  const [isCallView, setIsCallView] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isTextStreaming, setIsTextStreaming] = useState(false);
  const [characterGroups, setCharacterGroups] = useState([]);
  const [characterConfirmed, setCharacterConfirmed] = useState(false);
  const [messageId, setMessageId] = useState('');
  const audioPlayer = useRef(null);
  const callActive = useRef(false);
  const harkInitialized = useRef(false);
  const audioSent = useRef(false);
  const shouldPlayAudio = useRef(false);
  const audioQueue = useRef([]);
  const isConnecting = useRef(false);
  const isConnected = useRef(false);
  const isMobile = window.innerWidth <= 768;
  const appInit = useRef(false);
  const getAuthData = async () => {
    const token = localStorage.getItem('TOKEN');
    if (token === null || token === '') {
      localStorage.removeItem('TOKEN');
      localStorage.removeItem('USERINFO');
      return;
    }

    try {
      const scheme = window.location.protocol;
      const url = scheme + '//' + getHostName() + '/user_info';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const result = await response.json();
        if (result.code === 200) {
          const user = result.data;
          if (user.token !== null && user.token !== '') {
            setToken(user.token);
            setUser(user);
            localStorage.setItem('TOKEN', user.token);
            localStorage.setItem('USERINFO', JSON.stringify(user));
            isLoggedIn.current = true;
          }
        } else {
          // 无该用户
          if (result.code === 201) {
            localStorage.removeItem('TOKEN');
            localStorage.removeItem('USERINFO');
            return;
          }
          throw new Error(result.msg);
        }
      } else {
        throw new Error(response.text);
      }
    } catch (error) {
      console.log('获取用户信息', error);
    }
  };

  useEffect(() => {
    // 首次加载获取localStorage的配置信息
    if (!appInit.current) {
      appInit.current = true;
      getAuthData();
      const settings = localStorage.getItem('SETTINGS') || '{}';
      if (settings !== '{}') {
        let {
          _commMethod = 'Text',
          _preferredLanguage = 'English',
          _selectedModel = 'gpt-3.5-turbo-16k',
          _useSearch = false,
          _useMultiOn = false,
          _useEchoCancellation = false,
        } = JSON.parse(settings);
        setCommMethod(_commMethod);
        setPreferredLanguage(_preferredLanguage);
        setSelectedModel(_selectedModel);
        setUseSearch(_useSearch);
        setUseMultiOn(_useMultiOn);
        setUseEchoCancellation(_useEchoCancellation);
      }
    }
  }, []);

  const stopAudioPlayback = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      shouldPlayAudio.current = false;
    }
    audioQueue.current = [];
    setIsPlaying(false);
  };

  const checkWebsocket = event => {
    // 当前sessionId和websocket的sessionId不一致跳过
    const sessionId = getSessionIdTemp();
    if (sessionId === '') {
      return false;
    }
    const { currentTarget } = event;
    if (currentTarget === null) {
      return false;
    }
    const url = currentTarget.url;
    if (typeof url !== 'string') {
      return false;
    }
    if (url.indexOf(sessionId) === -1) {
      return false;
    }
    return true;
  };

  const handleSocketOnOpen = async event => {
    console.log('successfully connected');
    if (!checkWebsocket(event)) {
      return;
    }
    isConnected.current = true;
    await connectMicrophone(selectedDevice);
    if (isCallView && !useEchoCancellation) {
      initializeSpeechRecognition();
    }
    await connectPeer(selectedDevice);
  };

  const handleSocketOnMessage = event => {
    if (!checkWebsocket(event)) {
      return;
    }
    if (typeof event.data === 'string') {
      const message = event.data;
      if (!isTextStreaming) setIsTextStreaming(true);
      if (message === '[thinking]\n') {
        setIsThinking(true);
        return;
      }
      if (message === '[end]\n' || message.match(/\[end=([a-zA-Z0-9]+)\]/)) {
        setIsTextStreaming(false);
        setIsResponding(false);
        setTextAreaValue(prevState => prevState + '\n\n');
        const messageIdMatches = message.match(/\[end=([a-zA-Z0-9]+)\]/);
        if (messageIdMatches) {
          const messageId = messageIdMatches[1];
          setMessageId(messageId);
        }
        return;
      }
      setIsThinking(false);
      setIsResponding(true);
      shouldPlayAudio.current = true;
      if (message.startsWith('[+]You said: ')) {
        const msg = message.split('[+]You said: ');
        setTextAreaValue(prevState => prevState + `You> ${msg[1]}\n\n`);
        stopAudioPlayback();
        return;
      }
      setTextAreaValue(prevState => {
        // 句首
        if (prevState === '' || prevState.match(/\n\n$/)) {
          return prevState + `${selectedCharacter.name}> ${message}`;
        }
        return prevState + message;
      });
    } else {
      // binary data
      if (!shouldPlayAudio.current) {
        console.log('should not play audio');
        return;
      }
      audioQueue.current.push(event.data);
      if (audioQueue.current.length === 1) {
        setIsPlaying(true); // this will trigger playAudios in CallView.
      }
    }
  };

  const handleOnTrack = event => {
    if (event.streams && event.streams[0]) {
      audioPlayer.current.srcObject = event.streams[0];
    }
  };

  // Use custom hooks
  const { socketRef, send, connectSocket, closeSocket } = useWebsocket(
    token,
    handleSocketOnOpen,
    handleSocketOnMessage,
    selectedModel,
    preferredLanguage,
    useSearch,
    useQuivr,
    useMultiOn,
    selectedCharacter,
    setSessionId
  );
  const {
    recognize,
    startListening,
    stopListening,
    closeRecognition,
    initializeSpeechRecognition,
  } = useXunFeiRecognition(
    callActive,
    preferredLanguage,
    shouldPlayAudio,
    isConnected,
    audioSent,
    stopAudioPlayback,
    send,
    setTextAreaValue
  );
  const {
    isRecording,
    setIsRecording,
    connectMicrophone,
    startRecording,
    stopRecording,
    closeMediaRecorder,
  } = useRecord(
    isConnected,
    audioSent,
    callActive,
    send,
    closeSocket,
    recognize,
    isResponding
  );
  const {
    micStreamRef,
    audioContextRef,
    incomingStreamDestinationRef,
    connectPeer,
    closePeer,
  } = useWebRTC(handleOnTrack);
  const { speechEventsCallback, enableHark, disableHark } = useHark();
  const connectSocketWithState = useCallback(() => {
    isConnecting.current = true;
    connectSocket();
    isConnecting.current = false;
    useConversation.createConversation();
  }, [isConnecting, connectSocket]);

  const closeSocketWithState = () => {
    closeSocket();
  };

  const connect = async () => {
    try {
      connectSocketWithState();
    } catch (error) {
      console.error('Error during sign in or connect:', error);
    }
  };
  //   if (useEchoCancellation) {
  //     setIsRecording(false);
  //     disableHark();
  //   } else {
  //     stopRecording();
  //     stopListening();
  //   }
  //   stopAudioPlayback();
  //   callActive.current = false;
  // };

  // const handleContinueCall = () => {
  //   if (useEchoCancellation) {
  //     if (!harkInitialized.current) {
  //       speechEventsCallback(
  //         micStreamRef.current,
  //         () => {
  //           stopAudioPlayback();
  //           startRecording();
  //         },
  //         () => {
  //           // Stops recording and send interim audio clip to server.
  //           send('[&Speech]');
  //           stopRecording();
  //         },
  //         () => {
  //           send('[SpeechFinished]');
  //         }
  //       );
  //       harkInitialized.current = true;
  //     }
  //     setIsRecording(true);
  //     enableHark();
  //   } else {
  //     setIsRecording(true);
  //     startRecording();
  //     startListening();
  //   }
  //   shouldPlayAudio.current = true;
  //   callActive.current = true;
  // };

  const startRecord = () => {
    if (useEchoCancellation) {
      if (!harkInitialized.current) {
        speechEventsCallback(
          micStreamRef.current,
          () => {
            stopAudioPlayback();
            startRecording();
          },
          () => {
            // Stops recording and send interim audio clip to server.
            send('[&Speech]');
            stopRecording();
          },
          () => {
            send('[SpeechFinished]');
          }
        );
        harkInitialized.current = true;
      }
      setIsRecording(true);
      enableHark();
    } else {
      setIsRecording(true);
      startRecording();
      startListening();
    }
    callActive.current = true;
  };

  const stopRecord = () => {
    setIsRecording(false);
    if (useEchoCancellation) {
      disableHark();
    } else {
      stopRecording();
      stopListening();
    }
    callActive.current = false;
  };

  const handleContinueCall = () => {
    startRecord();
    shouldPlayAudio.current = true;
  };

  const handleStopCall = () => {
    stopRecord();
    stopAudioPlayback();
  };

  const handleDisconnect = async (reset = true) => {
    if (socketRef && socketRef.current) {
      // stop media recorder, speech recognition and audio playing
      stopAudioPlayback();
      closeMediaRecorder();
      if (!useEchoCancellation) {
        await closeRecognition();
      }
      closePeer();
      callActive.current = false;
      shouldPlayAudio.current = false;
      audioSent.current = false;
      harkInitialized.current = false;

      // reset everything to initial states
      if (reset) {
        setSelectedCharacter(null);
        setCharacterConfirmed(false);
        setCharacterGroups([]);
        setIsCallView(false);
        setTextAreaValue('');
      }

      // close web socket connection
      closeSocketWithState();
      isConnected.current = false;
    }
  };

  // Toast
  const [showToast, setShowToast] = useState(false);
  const [toastStatus, setToastStatus] = useState('info');
  const [toastMsg, setToastMsg] = useState('');
  const anchorOrigin = {
    horizontal: 'center',
    vertical: 'top',
  };
  const openToast = (msg, status = 'info') => {
    setToastMsg(msg);
    setToastStatus(status);
    setShowToast(true);
  };
  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <Router>
      <div className='app'>
        <Header
          user={user}
          isMobile={isMobile}
          isLoggedIn={isLoggedIn}
          setToken={setToken}
          handleDisconnect={handleDisconnect}
          setUser={setUser}
        />
        <Routes>
          <Route
            path='/'
            element={
              <Home
                isMobile={isMobile}
                selectedCharacter={selectedCharacter}
                setSelectedCharacter={setSelectedCharacter}
                isPlaying={isPlaying}
                characterGroups={characterGroups}
                setCharacterGroups={setCharacterGroups}
                characterConfirmed={characterConfirmed}
                token={token}
                setToken={setToken}
                isLoggedIn={isLoggedIn}
                commMethod={commMethod}
                preferredLanguage={preferredLanguage}
                selectedDevice={selectedDevice}
                selectedModel={selectedModel}
                useSearch={useSearch}
                useMultiOn={useMultiOn}
                useEchoCancellation={useEchoCancellation}
                openToast={openToast}
              />
            }
          />
          <Route
            path='/settings'
            element={
              <Settings
                isMobile={isMobile}
                commMethod={commMethod}
                setCommMethod={setCommMethod}
                preferredLanguage={preferredLanguage}
                setPreferredLanguage={setPreferredLanguage}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                isLoggedIn={isLoggedIn}
                token={token}
                setToken={setToken}
                useSearch={useSearch}
                setUseSearch={setUseSearch}
                useQuivr={useQuivr}
                setUseQuivr={setUseQuivr}
                quivrApiKey={quivrApiKey}
                setQuivrApiKey={setQuivrApiKey}
                quivrBrainId={quivrBrainId}
                setQuivrBrainId={setQuivrBrainId}
                useMultiOn={useMultiOn}
                setUseMultiOn={setUseMultiOn}
                useEchoCancellation={useEchoCancellation}
                setUseEchoCancellation={setUseEchoCancellation}
                send={send}
                openToast={openToast}
              />
            }
          />
          <Route
            path='/conversation'
            element={
              <Conversation
                isConnecting={isConnecting}
                isConnected={isConnected}
                isRecording={isRecording}
                isPlaying={isPlaying}
                isThinking={isThinking}
                isResponding={isResponding}
                audioPlayer={audioPlayer}
                handleStopCall={handleStopCall}
                handleContinueCall={handleContinueCall}
                audioQueue={audioQueue}
                audioContextRef={audioContextRef}
                audioSourceNodeRef={incomingStreamDestinationRef}
                setIsPlaying={setIsPlaying}
                handleDisconnect={handleDisconnect}
                setIsCallView={setIsCallView}
                send={send}
                stopAudioPlayback={stopAudioPlayback}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                messageInput={messageInput}
                setMessageInput={setMessageInput}
                useSearch={useSearch}
                setUseSearch={setUseSearch}
                setUseEchoCancellation={setUseEchoCancellation}
                callActive={callActive}
                startRecording={startRecording}
                stopRecording={stopRecording}
                preferredLanguage={preferredLanguage}
                setPreferredLanguage={setPreferredLanguage}
                selectedCharacter={selectedCharacter}
                setSelectedCharacter={setSelectedCharacter}
                setSelectedModel={setSelectedModel}
                setSelectedDevice={setSelectedDevice}
                setUseMultiOn={setUseMultiOn}
                connect={connect}
                messageId={messageId}
                token={token}
                isTextStreaming={isTextStreaming}
                sessionId={sessionId}
                setSessionId={setSessionId}
                openToast={openToast}
                socketRef={socketRef}
                startRecord={startRecord}
                stopRecord={stopRecord}
                shouldPlayAudio={shouldPlayAudio}
                initializeSpeechRecognition={initializeSpeechRecognition}
                closeRecognition={closeRecognition}
              />
            }
          />
          <Route path='/shared' element={<SharedConversation />} />
          <Route path='/create' element={<CharCreate token={token} />} />
          <Route
            path='/delete'
            element={
              <CharDelete
                token={token}
                isMobile={isMobile}
                characterGroups={characterGroups}
              />
            }
          />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/support' element={<Support />} />
          <Route
            path='/login'
            element={
              <Login
                isLoggedIn={isLoggedIn}
                setToken={setToken}
                setUser={setUser}
                isMobile={isMobile}
                openToast={openToast}
                closeToast={closeToast}
              />
            }
          />
          <Route
            path='/auth'
            element={
              <Auth
                isLoggedIn={isLoggedIn}
                isMobile={isMobile}
                setToken={setToken}
                setUser={setUser}
                openToast={openToast}
              />
            }
          />
          <Route
            path='/user'
            element={
              <User
                isLoggedIn={isLoggedIn}
                user={user}
                setUser={setUser}
                openToast={openToast}
              />
            }
          />
          <Route path='/bindWx' element={<BindWx></BindWx>} />
          <Route
            path='/libai'
            element={<Libai openToast={openToast}></Libai>}
          />
          <Route
            path='/mobile'
            element={<Mobile openToast={openToast}></Mobile>}
          />
          <Route
            path='/jintaiyang'
            element={<Jintaiyang openToast={openToast}></Jintaiyang>}
          />
          <Route
            path='/Survey'
            openToast={openToast}
            element={<Survey openToast={openToast}></Survey>}
          />
        </Routes>

        {/* <Footer /> */}
        <Snackbar
          open={showToast}
          anchorOrigin={anchorOrigin}
          autoHideDuration={1000}
          onClose={closeToast}
        >
          <Alert severity={toastStatus}>{toastMsg}</Alert>
        </Snackbar>
      </div>
    </Router>
  );
};

export default App;
