// src/Survey.js

import React, { useRef, useEffect, useState } from 'react';
// import useSpeechEvaluation from '../hooks/useSpeechEvaluation.js';

import './index.css';
import bg from './images/bg.png';
import rw from './images/rw.png';
import icon1 from './images/icon.png';

function Survey({ openToast, appInit }) {
  //进度条style
  const jinduBgRef = useRef(null);
  const jinduRef = useRef(null);
  //进度数字 1 / 5
  const [currentStep, setCurrentStep] = useState(0); // 定义当前步骤状态
  const [totalSteps, setTotalSteps] = useState(3); // 定义总步骤状态
  const [nexta, setNexta] = useState(0); // 定义总步骤状态
  //
  const [study_period, setStudy_period] = useState(''); // 阶段
  const [grade, setGrade] = useState(''); // 年级

  // 数据
  const [steps, setSteps] = useState([
    {
      title: '您孩子当前的阶段',
      type: 'sele',
      list: [
        { name: '幼儿园', isActive: false },
        { name: '小学', isActive: false },
        { name: '初中', isActive: false },
        { name: '高中', isActive: false },
        { name: '其他', isActive: false },
      ],
    },
    {
      title: '您孩子当前的年级',
      type: 'sele',
      list: [
        { name: '一年级', isActive: false },
        { name: '二年级', isActive: false },
        { name: '三年级', isActive: false },
        // { name: '四年级', isActive: false },
        // { name: '五年级', isActive: false },
        // { name: '六年级', isActive: false },
      ],
    },
    {
      title: '起个响亮的名字吧',
      type: 'input',
      desc: '建议使用英文名字 <br/> 长度最长7个汉字、14个英文字符哦~',
      value: '',
    },
    // ...更多的步骤对象
  ]);
  const [info, setInfo] = useState({}); // 定义总步骤状态

  //获取进度条背景宽度
  const getWidth = c => {
    if (jinduBgRef.current) {
      const width = jinduBgRef.current.getBoundingClientRect().width;
      setWidth(width / c);
    }
  };
  //设置进度
  const setWidth = newWidth => {
    if (jinduRef.current) {
      jinduRef.current.style.width = `${newWidth}px`;
    }
  };
  //选择题目
  const activeItem = index => {
    switch (info.type) {
      //单选题
      case 'sele':
        updateListItem(index);
        currentStep == 1 &&
          setStudy_period(steps[currentStep - 1].list[index].name);
        currentStep == 2 && setGrade(steps[currentStep - 1].list[index].name);
        setNexta(1);
        break;
    }
  };
  //题单选
  const updateListItem = index => {
    setInfo(prevInfo => {
      // 创建一个新的list数组，更新isActive状态
      const newList = prevInfo.list.map((item, listIndex) => ({
        ...item,
        isActive: listIndex === index ? true : false, // 只有被点击的元素isActive状态取反，其他都设置为false
      }));

      // 返回新的状态对象
      return {
        ...prevInfo,
        list: newList,
      };
    });
  };
  //题输入框
  const handleInputChange = v => {
    setInfo({
      ...info,
      value: v.target.value,
    });
    if (v.target.value) {
      setNexta(1);
    } else {
      setNexta(0);
    }
  };
  //下一步
  const nextFun = () => {
    // console.log('Next function called');
    if (!nexta && currentStep != 0) {
      info.type == 'sele' && openToast('请选择后再提交');
      info.type == 'input' && openToast('请填写后再提交');
      return;
    }
    //设置进度条
    if (currentStep != totalSteps) {
      let n = 1;
      //如果是幼儿园 / 其他直接跳到第三题
      if (info.type == 'sele') {
        const activeItem = info.list.find(item => item.isActive);
        activeItem.name == '幼儿园' && (n = 2);
        activeItem.name == '其他' && (n = 2);
        activeItem.name == '小学' &&
          steps[1].list.push(
            { name: '四年级', isActive: false },
            { name: '五年级', isActive: false },
            { name: '六年级', isActive: false }
          );
      }
      let current = currentStep + n;
      setCurrentStep(current);
      let c = totalSteps - current;
      getWidth(c + 1);
      console.log(c, current);
      //设置题目
      setInfo(steps[current - 1]);
      setNexta(0);
    } else {
      openToast('提交');
      const stepData = {
        nickname: info.value,
        study_period: study_period,
        grade: `${study_period} ${grade}`,
      };
      console.log(stepData);
    }
  };
  // 使用useEffect在组件挂载后调用getWidth
  useEffect(() => {
    //初始化
    // setInfo(steps[currentStep]);
    // let c = totalSteps - currentStep;
    // getWidth(c);
    nextFun();
  }, []);

  return (
    <div className='survey'>
      <img className='bg' src={bg} />
      <div className='detail'>
        <img className='rw' src={rw} />
        <div className='hint'>
          <div className='hint_text'>
            想多认识你一点 <img className='icon1' src={icon1} />
            <br /> 为你量身打造最好的课程
          </div>
        </div>
        <div className='info'>
          {/* 进度条 */}
          <div className='top_jindu'>
            <div className='jindu_bg' ref={jinduBgRef}>
              <div className='jindu' ref={jinduRef}></div>
            </div>
            <div className='now'>{`${currentStep}/${totalSteps}`}</div>
          </div>
          {/* 题 */}
          <div className='titlea'>{info.title}</div>
          {info.desc && (
            <div
              className='desc'
              dangerouslySetInnerHTML={{ __html: info.desc }}
            ></div>
          )}
          {/* 单选 */}
          {info.type === 'sele' && (
            <div className='selections'>
              {info.list.map((item, index) => (
                <div
                  className={`
                selection
                ${item.isActive ? 'selection-a' : ''} 
              `}
                  key={index}
                  onClick={() => activeItem(index)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
          {/* 输入框 */}
          {info.type === 'input' && (
            <input
              className={`
                input_no
                ${info.value ? 'input-a' : ''} 
              `}
              type='text'
              value={info.value}
              onChange={handleInputChange}
              placeholder='例如：James'
            />
          )}
          {/* 下一步按钮 */}
          <div
            className={`
                next
                ${nexta ? 'next-a' : ''} 
              `}
            onClick={nextFun}
          >
            继续
          </div>
        </div>
      </div>
    </div>
  );
}

export default Survey;
