/**
 * src/components/Header/index.jsx
 * logo
 *
 * created by Lynchee on 7/16/23
 */

import React from 'react';
import logo from '../../assets/images/big_logo.png';
import './style.css';
import SignIn from '../Auth/SignIn';
import SignOut from '../Auth/SignOut';
import { Navbar } from '@nextui-org/react';
import { useState } from 'react';
import { useEffect } from 'react';
import IconButton from '../Common/IconButton';
import { TbSettingsFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const Header = ({
  user,
  isMobile,
  isLoggedIn,
  setToken,
  handleDisconnect,
  setUser,
}) => {
  const navigator = useNavigate();
  const [showHeader, setShowHeader] = useState(true);
  const [showSetting, setShowSetting] = useState(true);
  const [showLogin, setShowLogin] = useState(true);
  const [logoWidth, setLogoWidth] = useState(0);
  const [logoHeight, setLogoHeight] = useState(0);

  const toSettingPage = () => {
    navigator('/settings');
  };

  const toHomePage = () => {
    navigator('/');
  };

  useEffect(() => {
    const screenWidth = window.screen.width;
    const height = isMobile ? screenWidth * 0.12 : screenWidth * 0.04;
    setLogoHeight(height);
    const width = height * (553 / 172);
    setLogoWidth(width);
  }, []);

  useEffect(() => {
    const blackList = ['/settings', '/conversation'];
    const pathname = location.pathname;
    setShowHeader(
      pathname !== '/auth' &&
        pathname !== '/libai' &&
        pathname !== '/mobile' &&
        pathname !== '/jintaiyang' &&
        pathname !== '/survey'
    );
    setShowSetting(blackList.indexOf(pathname) === -1);
    setShowLogin(pathname !== '/login');
  }, [navigator]);

  return (
    <Navbar
      id='navbar'
      variant='floating'
      isBordered
      style={{
        display: showHeader ? 'flex' : 'none',
      }}
    >
      <Navbar.Brand
        css={{
          '@xs': {
            w: '12%',
          },
        }}
        onClick={toHomePage}
      >
        <img src={logo} alt='Logo' width={logoWidth} height={logoHeight} />
      </Navbar.Brand>
      <Navbar.Content
        id='navbar'
        css={{
          '@xs': {
            w: '19%',
            jc: 'flex-end',
          },
        }}
      >
        <div style={{ display: showSetting ? 'block' : 'none' }}>
          <IconButton
            Icon={TbSettingsFilled}
            className='icon-blue'
            noMargin={true}
            onClick={toSettingPage}
          />
        </div>
        {showLogin ? (
          user ? (
            <SignOut
              isLoggedIn={isLoggedIn}
              user={user}
              setUser={setUser}
              isMobile={isMobile}
            />
          ) : (
            <SignIn isLoggedIn={isLoggedIn} setToken={setToken} />
          )
        ) : (
          <div></div>
        )}
      </Navbar.Content>
    </Navbar>
  );
};

export default Header;
